import React, { useState } from 'react';

const AccessForm = ({ onAccessGranted }) => {
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    if (password === 'smile') { 
      onAccessGranted();
    } else {
      alert('Zugriff verweigert');
    }
  };

  return (
    <div className="access-container">
      <form onSubmit={handleSubmit} className="access-form">
        <input
          type="password"
          className="access-input" // New class for styling
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Bitte Zugangscode eingeben"
        />
        <button type="submit" className="access-button">Senden</button>
      </form>
    </div>
  );
};

export default AccessForm;